import React from 'react'
import squiddy from "../images/illustrations/squiddyColors.png"
import blurredColors from "../images/illustrations/blurredColors.svg"
import { useInView } from 'react-intersection-observer'

const Component1 = () => {

    const { ref: ref1, inView: inView1 } = useInView()
    const { ref: ref2, inView: inView2 } = useInView()

    const style1 = {left: inView1? "0rem": "-6rem"}
    const style2 = {right: inView2? "0rem": "-6rem"}

    return (
        <div className='flex flex-col md:flex-row justify-between w-full md:pl-[4rem]'>
            <div style={style1} ref={ref1} className="relative transition-all duration-1000 delay-500 flex md:w-[60%] flex-col gap-6">
                <h1 className='text-6xl bangers text-[#0592C8]'>Dive into the World of SQW Coin!</h1>
                <p className='text-[#7D7E80]'>The meme coin inspired by everyone's <br /> favorite undersea clarinetist.</p>
                <div>
                    <a className='button z-[2] relative' href='https://www.pinksale.finance/solana/launchpad/9btpuM572W22YUxJBCe5eZVsZSmvu7kA6rsMMgyTsLtg'>Get SQW Coin</a>
                </div>
            </div>
            <div style={style2} ref={ref2} className='relative md:w-[40%] transition-all duration-1000 delay-500'>
                <img src={squiddy} className='rounded-b-full relative pr-4 md:pr-[5rem] scale-[1.5] md:scale-[1.8]' alt="" />
            </div>
        </div>
    )
}

export default Component1

import React from 'react'
import flowerySquiddy from "../images/illustrations/flowerySquiddy.svg"
import blurredColors from "../images/illustrations/blurredColors.svg"
import { useInView } from 'react-intersection-observer'

const Component2 = () => {

    const { ref: ref1, inView: inView1 } = useInView()
    const { ref: ref2, inView: inView2 } = useInView()

    const style1 = {left: inView1? "0rem": "-6rem"}
    const style2 = {right: inView2? "0rem": "-6rem"}

    return (
        <div className='flex flex-col md:flex-row justify-between items-center w-full md:px-[2rem] lg:px-[4rem]'>
            <div ref={ref1} style={style1} className='relative transition-all duration-1000 delay-500 md:w-[35%]'>
                <img src={flowerySquiddy} className='scale-[0.9] z-10 rounded-b-full relative' alt="" />
                {/* <img src={blurredColors} className='absolute z-[5] top-[-30%] scale-[0.7] right-[-10%]' alt="" /> */}
            </div>
            <div ref={ref2} style={style2} className="relative transition-all duration-1000 delay-500 flex md:w-[55%] lg:w-[45%] flex-col gap-6">
                <h1 className='bangers'>About SQW Coin</h1>
                <p className=''>SQW Coin was born from the depths of the whimsical world of Bikini Bottom,
                    inspired by the beloved and ever-relatable character, Squidward Tentacles. As a meme coin, SQW Coin embraces the humor, charm, and occasional grumpiness of Squidward himself.
                    Our mission is to bring a splash of fun and creativity to the cryptocurrency space while building a strong, vibrant community.</p>
                <div>
                    <a className='button relative z-10' href='https://www.pinksale.finance/solana/launchpad/9btpuM572W22YUxJBCe5eZVsZSmvu7kA6rsMMgyTsLtg'>Join The Adventure</a>
                </div>
            </div>
        </div>
    )
}

export default Component2

import React from 'react'
import dextools from "../images/illustrations/dextools.svg"

const Component6 = () => {

    const sponsors = [
        { image: dextools, text: "DEXTools", link: "https://www.dextools.io/app/en/solana/pair-explorer/9hpjc4DiX7htjfDuk2vji4QzKtuRLdKUff5YSks8G3T3?t=1716676667167" }
    ]

    return (
        <div className='w-full mt-[10%] py-[2rem] relative'>
            {/* <h2 className="text-center bangers pb-[6rem]">SPONSORS</h2> */}
            <div className='flex items-center justify-center w-full gap-2 md:gap-[2rem] px-2 md:px-[15%]'>
                {
                    sponsors.map((element, index) => (
                       
                            <a target='_blank' href={element.link} className='bg-[#f1dfdf1b] opacity-[90%] flex flex-col w-3/4 md:w-1/3 border-2 rounded-[2rem] items-center py-[4rem] gap-4 md:px-[2rem]' key={index}>
                                <img className='w-1/2' src={element.image} alt="" />
                                <p className='text-xl font-medium'>{element.text}</p>
                            </a>
                       
                    ))
                }
            </div>
        </div>
    )
}

export default Component6

import React from 'react';
import Header from './Header';
import Component1 from './Component1';
import Component2 from './Component2';
import Component3 from './Component3';
import Component4 from './Component4';
import Footer from './Footer';
import Component5 from './Component5';
import Component6 from './Component6';
import { Element } from 'react-scroll';

const Hero = () => {
    return (
        <div className='relative flex flex-col items-center text-[0.82rem] md:text-[1rem] gap-[3rem]'>
            <div id='home' className='w-full max-w-[80rem] pt-[2rem] px-[2rem] relative z-10'>
                <Header />
            </div>
            <Element name='home' className='w-full max-w-[80rem] relative pt-[4rem] md:pt-0 px-[2rem]'>
                <Component1 />
                <div className="bub b"></div>
                <div className="bub c"></div>
                <div className="bub d"></div>
                <div className="bub e"></div>
            </Element>
            <Element name='about' id='about' className='w-full max-w-[80rem] relative px-[2rem]'>
                <Component2 />
                <div className="bub b"></div>
                <div className="bub c"></div>
                <div className="bub d"></div>
                <div className="bub e"></div>
            </Element>
            <Element name='tokenomics' id='tokenomics' className='w-full max-w-[80rem] relative'>
                <Component3 />
                <div className="bub b"></div>
                <div className="bub c"></div>
                <div className="bub d"></div>
                <div className="bub e"></div>
            </Element>
            <Element name='roadmap' id='roadmap' className='w-full relative'>
                <Component4 />
                <div className="bub b"></div>
                <div className="bub c"></div>
                <div className="bub d"></div>
                <div className="bub e"></div>
            </Element>
            <div className="w-full max-w-[80rem] relative">
                <Component5 />
                <div className="bub b"></div>
                <div className="bub c"></div>
                <div className="bub d"></div>
                <div className="bub e"></div>
            </div>
            <div className="w-full max-w-[80rem] relative">
                <Component6 />
                <div className="bub b"></div>
                <div className="bub c"></div>
                <div className="bub d"></div>
                <div className="bub e"></div>
            </div>
            <div className='w-full max-w-[80rem] relative'>
                <Footer />
                <div className="bub b"></div>
                <div className="bub c"></div>
                <div className="bub d"></div>
                <div className="bub e"></div>
            </div>
            <div></div>
        </div>
    );
};

export default Hero;

import React from 'react';
import x from "../images/icons/x.svg";
import telegram from "../images/icons/telegram.svg";
import weirdo from "../images/icons/weirdo.svg";
import { useState } from 'react';
import { Link } from 'react-scroll';

const Header = () => {
    const tabs = [
        { name: 'HOME', link: "home" },
        { name: 'ABOUT', link: "about" },
        { name: 'TOKENOMICS', link: "tokenomics" },
        { name: 'ROADMAP', link: "roadmap" },
    ];

    const socialLinks = [
        { icon: telegram, link: "https://t.me/SQW_COIN" },
        { icon: x, link: "https://x.com/SQW_COIN" }
    ];

    const [visibleMobileMenu, toggleMobileMenu] = useState(false);

    return (
        <>
            <div className={`w-full rounded-full border-2 mt-[2rem] border-[#FF6F61] hidden md:flex justify-between p-2 px-[5rem] mb-[6rem]`}>
                <div>
                    <img src={weirdo} className='scale-[0.8]' alt="" />
                </div>
                <div className="flex gap-2 md:gap-4 lg:gap-8 items-center">
                    {tabs.map((tab, index) => (
                        <Link
                            to={tab.link}
                            smooth={true}
                            duration={500}
                            key={index}
                            className='font-semibold text-base cursor-pointer'
                        >
                            {tab.name}
                        </Link>
                    ))}
                </div>
                <div className="flex gap-2 md:gap-4 lg:gap-8 items-center">
                    {socialLinks.map((link, index) => (
                        <a target='_blank' href={link.link} key={index}><img className='scale-[0.8]' src={link.icon} alt="social icon" /></a>
                    ))}
                </div>
            </div>
            <div className='absolute left-4 top-2 md:hidden'>
                <img src={weirdo} className='scale-[0.8]' alt="" />
            </div>
            <i className={`fa-solid fa-bars text-4xl absolute right-4 top-4 md:hidden ${visibleMobileMenu ? "hidden" : "flex"}`} onClick={() => toggleMobileMenu(!visibleMobileMenu)}></i>
            <div className={`fixed left-0 flex flex-col pl-6 h-screen pt-[6rem] py-10 transition-all duration-500 z-[1000] ${visibleMobileMenu ? "w-5/6 opacity-1 bg-black" : "w-0 bg-transparent opacity-0"}`}>
                <i className='text-3xl font-semibold text-red-500 absolute right-4 top-8' onClick={() => toggleMobileMenu(!visibleMobileMenu)}>X</i>
                <h2 className={`text-4xl pb-[4rem] font-semibold bangers ${visibleMobileMenu ? "w-auto" : "w-0"}`}>SQW Coin</h2>
                <div className='flex flex-col gap-10 pb-10'>
                    {tabs.map((tab, index) => (
                        <Link
                            to={tab.link}
                            smooth={true}
                            duration={500}
                            onClick={() => toggleMobileMenu(false)}
                            key={index}
                            className={`font-semibold text-base ${visibleMobileMenu ? "w-auto" : "w-0"}`}
                        >
                            {tab.name}
                        </Link>
                    ))}
                </div>
                <div className="flex gap-2 md:gap-4 lg:gap-8 items-center">
                    {socialLinks.map((link, index) => (
                        <a target='_blank' className='w-[3rem] h-[3rem]' href={link.link} key={index}><img className='scale-[0.8]' src={link.icon} alt="social icon" /></a>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Header;

import React from 'react'
import squiddy1 from "../images/illustrations/squiddy1.jpg"
import squiddy2 from "../images/illustrations/squiddy2.avif"

const Component5 = () => {

    const elements = [
        {image: squiddy1, text: "Crate Solana wallet(Phantom or Solflare)"},
        {image: squiddy2, text: "Go to Raydium or Jupiter"},
        {image: squiddy1, text: "Change Solana for $SQW Coin"}
    ]
    return (
        <div className='w-full mt-[10%] py-[2rem] relative'>
            <h2 className="text-center bangers pb-[6rem]">HOW TO BUY</h2>
            <div className='flex flex-col w-full items-center gap-[2rem]'>
               {
                elements.map((element,index)=> (
                    <div className='flex w-full justify-center items-center gap-4 md:gap-[4rem] md:px-[2rem]' key={index}>
                      
                            <img src={element.image} className='w-[5rem] h-[5rem] bg-[#6868680e] rounded-[2rem]' alt="" />
                       
                        <div className='w-[70%] p-6 bg-[#6868680e] border-t-2 border-r-2 border-[#FF6F61] rounded-[2rem] text-center font-medium'>{element.text}</div>
                    </div>
                ))
               }
                {/* <div className='flex w-full justify-center items-center gap-4 md:gap-[4rem] md:px-[2rem]'>
                    <img src={squiddy2} className='w-[5rem] h-[5rem] bg-[#6868680e] rounded-[2rem]' alt="" />
                    <div className='w-[70%] p-6 bg-[#6868680e] border-t-2 border-r-2 border-[#FF6F61] rounded-[2rem] text-center font-medium'>https://www.pinksale.finance/solana/launchpad/9btpuM572W22YUxJBCe5eZVsZSmvu7kA6rsMMgyTsLtg</div>
                </div> */}
            </div>
        </div>
    )
}

export default Component5
import React from 'react'
import squiddyHouse from "../images/illustrations/squiddyHouse.svg"
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Component3 = () => {

    const taxes = [
        { name: "Community Distribution", info: 1 },
        { name: "Development Fund", info: 2 },
        { name: "Marketing and Partnerships", info: 2 },
    ]


    const aboutToken = [
        { name: "Token Name", info: "SQW Coin" },
        { name: "Tax", info: "0%" },
        { name: "Token Symbol", info: "$SQW" },
        { name: "Total Supply", info: "1,000,000,000" },
        { name: "LP", info: "burn" },
    ]




    return (
        <div>
            <div className="w-full relative">
                <img src={squiddyHouse} className='bottom-[-10%] scale-[1.3] left-0 absolute w-full h-full border-none' alt="" />
                <h2 className="text-center bangers mb-[6rem] relative z-[5]">Token Tokenomics</h2>
                <div className="flex flex-col md:flex-row w-full gap-[2rem] items-center justify-between z-[5] relative md:px-[2rem]">
                    {/* <div className="flex flex-col gap-10 items-center md:w-[40%]">
                        <h3 className='text-3xl font-medium'>Taxes</h3>
                        <div className='flex flex-col gap-[1.8rem]  items-center md:items-start w-full'>
                            {
                                taxes.map((tax, index) => (
                                    <div key={index} className='p-3 flex justify-between bg-[#6868680e] rounded-full border-[#FF6F61] border-t-2 border-r w-[90%]'>
                                        <p className='font-medium'>{tax.name}:</p>
                                        <p className='font-medium'>{tax.info}%</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div> */}
                    <div className="flex flex-col gap-10 items-center w-full">
                        <h3 className='text-3xl font-medium'>About Token</h3>
                        <div className='flex flex-col items-center gap-[1.8rem] w-full max-w-[40rem]'>
                            {
                                aboutToken.map((tax, index) => (
                                    <div key={index} className='p-3 border-t-2 border-r flex justify-between bg-[#6868680e] rounded-full border-[#FF6F61] w-[90%]'>
                                        <p className='font-medium'>{tax.name}</p>
                                        <p className='font-medium'>{tax.info}</p>
                                    </div>
                                ))
                            }
                            <div className='p-3 flex justify-center w-[90%]'>
                                <p className='font-medium text-center'>Token Address</p>
                            </div>
                            <div className='p-3 flex justify-center relative bg-[#6868680e] rounded-full border-[#FF6F61] border-t-2 border-r w-[90%]'>
                                <p className='font-medium text-center'>4T7G151ZabQXUkFoGcLa5zADhXdzXfMqsRurxGxmSaK8</p>
                                <CopyToClipboard text={'4T7G151ZabQXUkFoGcLa5zADhXdzXfMqsRurxGxmSaK8'} >
                                <p className=" absolute top-1/2 right-4 transform -translate-y-1/2">copy</p>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Component3

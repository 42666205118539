import Hero from "./components/Hero";

function App() {
  return (
    <div className="App w-full h-screen overflow-x-hidden bg-[#03030c] text-[#e0e0e0] relative">
       <Hero /> 
    </div>
  );
}

export default App;

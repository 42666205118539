import React from 'react'
import weirdo from "../images/icons/weirdo.svg"
import telegram from "../images/illustrations/greenTelegram.svg"
import x from "../images/illustrations/greenX.svg"
import weirdo2 from "../images/illustrations/weirdo2.svg"

const Footer = () => {
    const tabs = [
        { name: 'HOME', link: "home" },
        { name: 'ABOUT', link: "about" },
        { name: 'TOKENOMICS', link: "tokenomics" },
        { name: 'ROADMAP', link: "roadmap" },
    ]

    const socialLinks = [
        { icon: telegram, link: "https://t.me/SQW_COIN" },
        { icon: x, link: "https://x.com/SQW_COIN" }
    ]

    const footerLinks = [
        { name: '© 2024 SQW Coin', link: "" },
        { name: 'Cookie Settings', link: "" },
        { name: 'Terms and Condition', link: "" },
        { name: 'Privacy Policy', link: "" }
    ]
    return (
        <div className='flex justify-center md:pt-[2rem]'>
            <div className='flex flex-col gap-4 pt-[10rem] relative z-[5]'>
                <div className="flex items-center justify-center">
                    <img src={weirdo} alt="" />
                    <p className='text-lg md:text-2xl font-semibold'>SQW Coin</p>
                </div>
                <div className="flex w-full justify-center gap-4 lg:justify-evenly pb-4">
                    {
                        tabs.map((tab, index) => (
                            <a href={`#${tab.link}`}
                            key={index} className='font-semibold text-base cursor-pointer'>{tab.name}</a>
                        ))
                    }
                </div>
                <div className="flex w-full justify-center gap-[2rem] pb-[4rem]">
                    {
                        socialLinks.map((tab, index) => (
                            <a target='_blank' href={tab.link}><img key={index} className='scale-[0.8]' src={tab.icon}>{tab.name}</img></a>
                        ))
                    }
                </div>
                <div className="grid relative grid-cols-2 md:flex border-t-2 border-[#9c9999] gap-4 md:gap-[3rem] justify-end">
                    {
                        footerLinks.map((tab, index) => (
                            <div key={index} className='text-[#D8D8D8] flex justify-center z-[5]'>{tab.name}</div>
                        ))
                    }
                </div>
            </div>
            <img src={weirdo2} className='absolute scale-[0.8] md:right-[-10%] hidden md:flex md:bottom-[-2%]' alt="" />
        </div>
    )
}

export default Footer
import React from 'react'
import roadmap1 from "../images/illustrations/roadmap1.svg"
import roadmap2 from "../images/illustrations/roadmap2.svg"
import roadmap3 from "../images/illustrations/roadmap3.svg"
import pink from "../images/illustrations/pink.svg"
import { useInView } from 'react-intersection-observer'
import one from "../images/illustrations/one.svg"
import two from "../images/illustrations/two.svg"
import three from "../images/illustrations/three.svg"

const Component4 = () => {
    const { ref: ref1, inView: inView1 } = useInView()
    const { ref: ref2, inView: inView2 } = useInView()
    const { ref: ref3, inView: inView3 } = useInView()

    const roadmaps = [
        { info: ["Creating CA", "Creating Socials", "Creating Presale"], ref: ref1, style: inView1 ? "top-[0rem] right-[3rem] md:right-[0rem] md:top-[3rem]" : "top-[0rem] md:top-[0rem] right-[0rem] md:right-[0rem]", image: one },
        { info: ["Launch on dex", "CoinMarketCap", "CoinGecko listing"], ref: ref2, style: inView2 ? "top-[0rem] right-[0rem] md:right-[0rem] md:top-[0rem]" : "top-[0rem] md:top-[0rem] right-[0rem] md:right-[0rem]", image: two },
        { info: ["Trending on all dex", "CEX listing", "Conquering the meme market"], ref: ref3, style: inView3 ? "top-[0rem] right-[-3rem] md:right-[0rem] md:top-[-3rem]" : "top-[0rem] md:top-[0rem] right-[0rem] md:right-[0rem]", image: three }
    ]


    return (
        <div className='w-full py-[2rem] relative' style={{ backgroundImage: `url(${pink})`, backgroundSize: "cover" }}>
            {/* <img src={pink} className='absolute w-full h-full scale-x-[2] scale-y-[1.4]' alt="" /> */}
            <h2 className='bangers text-center relative'>ROADMAP</h2>
            <div className='flex flex-col md:flex-row gap-[0.5rem] md:gap-[2rem] justify-center p-[4rem]'>
                {
                    roadmaps.map((map, index) => (
                        <div ref={map.ref} key={index} className={`transition-all duration-1000 delay-500 relative ${map.style} p-6 items-center justify-between w-[15rem] h-[20rem] rounded-[2rem] bg-[#FFFFFF1A] flex flex-col`}>
                            <div className="h-1/2">
                                <img className='scale-[0.9]' src={map.image} alt="" />
                            </div>
                            <div className="h-1/2 justify-end w-full flex flex-col text-white gap-2">
                                {
                                    map.info.map((info, index) => (
                                        <p key={index}>{info}</p>
                                    ))
                                }
                            </div>
                        </div>

                    ))
                }
            </div>
        </div>
    )
}

export default Component4